import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"

import Link from "../utils/link"

const Footer = ({ darkMode, setDarkMode }) => {
  const data = useStaticQuery(graphql`
    {
      contact: wpPost(title: { eq: "Contact Details" }) {
        acfcontactdetails {
          email
          instagram
          phonenumber
        }
      }
      footer: wpPost(title: { eq: "footer" }) {
        acffooter {
          contactlink {
            url
            title
          }
        }
      }
    }
  `)

  const contact = data.contact.acfcontactdetails
  const footer = data.footer.acffooter
  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    })
  }

  return (
    <footer className="footer">
      <div className="footer__address">
        <a href={`tel:${contact.phonenumber}`}>{contact.phonenumber} </a>
        <a href={`mailto:${contact.email}`}> {contact.email}</a>
        <a
          href={`https://instagram.com/${contact.instagram}`}
          target="_blank"
          rel="noreferrer"
        >
          @{contact.instagram}
        </a>
      </div>
      <Link
        to={footer.contactlink.url}
        className="footer__contact"
        aria-hidden="true"
        onClick={() => {
          setDarkMode(true)
        }}
      >
        {footer.contactlink.title}
      </Link>
      <div
        className="footer__backtotop"
        onClick={scrollToTop}
        aria-hidden="true"
      >
        Back to top
      </div>
      <div className="footer__toggle-btn">
        <Link to={darkMode ? "/" : "/indexpage"}>
          <div
            className="footer__toggle-btn__black-circle"
            aria-hidden="true"
            onClick={() => {
              setDarkMode(!darkMode)
            }}
          ></div>
        </Link>
      </div>
      <div className="footer__arrow-icon">
        <img src="/images/arrowup.svg" id="arrow" alt="" />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  darkMode: PropTypes.bool,
  setDarkMode: PropTypes.func,
}

export default Footer
