import React from "react"

import PropTypes from "prop-types"

import Link from "../utils/link"

const Header = ({ darkMode, setDarkMode }) => {
  return (
    <header className="header">
      <Link
        to="/"
        title="Site Name"
        className="header__logo"
        aria-hidden="true"
        onClick={() => {
          setDarkMode(false)
        }}
      >
        <svg
          width="431px"
          height="81px"
          viewBox="0 0 431 81"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <g transform="matrix(1 0 0 1 2.842171E-14 7.1054274E-15)">
              <path
                d="M10.5665 50.7049L5.72205e-05 50.7054L0 0L10.567 -0.000226021C11.1343 0.000292778 11.6307 0.00061512 12.1976 0.000254631C21.6297 0.000279427 29.2175 0.141818 35.8835 4.82238C42.2664 9.28963 45.6699 16.3107 45.6696 25.3885C45.6697 34.3949 42.2661 41.4147 35.8833 45.9539C29.5722 50.4206 22.5513 50.7059 14.1128 50.7046C13.3207 50.7052 12.4944 50.7051 11.6577 50.705C11.2953 50.705 10.9309 50.7049 10.5665 50.7049ZM13.6869 11.3474L13.6868 39.3589L16.0275 39.3586C26.5934 39.3595 31.6284 35.317 31.6288 25.3885C31.6286 15.3188 26.4516 11.3466 16.0269 11.3468L13.6869 11.3474Z"
                transform="translate(0 14.8144045)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M27.8701 1.13462L39.2871 1.13482L39.2875 36.0249L27.9413 36.0249L27.9404 30.4235C24.6794 35.316 20.9204 37.4437 15.3893 37.4439C6.24049 37.444 0.000461578 30.2106 0 18.6511C0.000238419 7.30425 6.24062 0.000297546 16.1694 0C21.4173 -0.000328064 25.1049 2.12691 27.871 6.8785L27.8701 1.13462ZM20.2821 27.3739C25.3174 27.3736 28.4372 24.0406 28.4374 18.5805C28.4377 13.1905 25.3175 9.71506 20.2822 9.71534C15.2471 9.71497 12.1976 13.1897 12.1978 18.5805C12.1979 24.0415 15.2468 27.3742 20.2821 27.3739Z"
                transform="translate(49.094387 29.423359)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M11.9568 43.4028L23.3523 39.2553L18.3312 25.4601C15.6398 18.0632 15.941 13.5012 21.1385 11.6093C25.004 10.2025 27.4093 12.0436 29.2772 17.1746L35.681 34.7674L47.2755 30.5478L40.4359 11.7554C38.7135 7.02321 37.2038 3.49807 33.9861 1.49909C31.0655 -0.305001 27.0668 -0.509497 22.8019 1.04206C17.0713 3.12819 14.1877 6.81872 13.0205 13.3571L10.5953 6.69245L0 10.549L11.9568 43.4028Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 98.62769 20.644995)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M17.3423 63.4128L30.3367 58.6827L23.2789 39.2906L40.6047 32.9842L47.6632 52.3769L60.6569 47.6461L43.3153 0L30.3209 4.72947L37.0638 23.2548L19.7379 29.5613L12.9951 11.0359L0 15.7654L17.3423 63.4128Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 166.92929 9.536743E-07)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M19.5012 0C30.5648 9.63211e-05 39.0749 8.36787 39.0738 18.7219C39.0744 29.0751 30.5646 37.4438 19.5011 37.4437C8.36813 37.4438 -4.14848e-05 29.0051 0 18.7222C-0.000405788 8.36831 8.43838 0.000139236 19.5012 0ZM19.5014 27.09C24.0399 27.0901 26.8053 23.8983 26.8061 18.7217C26.8058 13.544 24.0398 10.2829 19.5014 10.2822C14.9628 10.2822 12.1966 13.5445 12.1965 18.7213C12.197 23.8984 14.9628 27.09 19.5014 27.09Z"
                transform="translate(211.61067 29.423246)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M24.7112 10.0614L21.1455 0.266169C17.9882 -0.321242 15.1959 0.0911807 12.0633 1.23169C2.06774 4.87006 -2.44283 14.6625 1.31632 24.9916C5.29438 35.9204 15.0079 40.0069 24.7366 36.466C27.8019 35.3495 30.2428 33.5564 32.3756 31.1196L28.7132 21.0581C27.5082 23.7605 25.9573 25.3052 23.4917 26.2028C18.6264 27.9733 14.4823 25.708 12.6871 20.7775C10.7464 15.4462 12.6727 11.1984 17.1373 9.57346C19.4698 8.7246 21.8084 8.92978 24.7112 10.0614L24.7112 10.0614Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 260.68936 24.139566)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M18.3607 54.5941L29.7568 50.4466L24.4448 35.8519L42.1508 45.9353L57.4118 40.3803L35.0758 29.19L44.1216 8.01238L29.7273 13.2517L23.0147 31.9205L11.3955 0L0 4.14755L18.3607 54.5941Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 287.57422 7.938198)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M11.9568 37.0727L23.5527 32.8531L11.5945 0L0 4.21961L11.9568 37.0727Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 331.2548 26.592651)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M0.402395 9.14517C1.63966 12.5447 5.5539 14.5911 9.15178 13.2811C12.6179 12.0198 14.5071 8.08701 13.1978 4.48844C11.8885 0.89055 8.00304 -0.865504 4.47172 0.419113C0.93971 1.7051 -0.85889 5.67973 0.402395 9.14517L0.402395 9.14517Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 331.92255 10.905097)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M11.9582 43.4027L23.353 39.2551L18.3319 25.4606C15.6398 18.0637 15.941 13.5017 21.1385 11.6098C25.004 10.2023 27.41 12.0442 29.2779 17.1751L35.681 34.7679L47.2762 30.5476L40.4366 11.7553C38.7142 7.02441 37.2052 3.49789 33.9867 1.4996C31.0655 -0.305177 27.0668 -0.509673 22.8019 1.04257C17.0713 3.12801 14.1884 6.81923 13.0205 13.3562L10.5947 6.69296L0 10.5496L11.9582 43.4027Z"
                transform="matrix(0.9396926 0.34202012 -0.34202012 0.9396926 352.37515 20.644817)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
              <path
                d="M27.8704 1.13486L39.288 1.13529L39.2877 36.0252C39.2878 46.663 32.9046 51.5562 19.502 51.5563C8.29761 51.556 2.12748 47.0881 1.77284 39.9265L14.5379 39.9266C14.8929 42.0531 17.0202 43.4014 20.4241 43.401C26.9483 43.4008 27.4446 39.9265 27.4447 33.4016L27.4448 30.2111C24.8215 34.6077 20.85 36.7354 15.3184 36.7347C6.24166 36.7355 -0.000238419 29.7139 0 18.3671C0.000703812 7.23372 6.24089 0.000533104 16.1691 1.90735e-06C21.4166 0.000318527 25.1048 2.05615 27.8702 6.73738L27.8704 1.13486ZM20.2825 27.161C25.3877 27.1613 28.438 23.8286 28.4369 18.5089C28.438 13.1908 25.3885 9.8579 20.2824 9.85798C15.1764 9.85806 12.055 13.1912 12.056 18.5094C12.0559 23.8286 15.1762 27.1617 20.2825 27.161Z"
                transform="translate(390.94034 29.422783)"
                fill={darkMode ? "#FFFFFF" : "#000000"}
                fillRule="evenodd"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      </Link>
      <nav className="header__nav">
        <Link
          to={darkMode ? "/" : "/indexpage"}
          className={
            darkMode
              ? "header__toggle-btn header__toggle-btn_light"
              : " header__toggle-btn "
          }
          aria-hidden="true"
          onClick={() => {
            setDarkMode(!darkMode)
          }}
        ></Link>
      </nav>
    </header>
  )
}

Header.propTypes = {
  darkMode: PropTypes.bool,
  setDarkMode: PropTypes.func,
}

export default Header
