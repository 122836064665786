import React from "react"

import PropTypes from "prop-types"

import "../assets/scss/main.scss"
import Footer from "../components/footer"
import Header from "../components/header"

import "./index.scss"

const Layout = ({ children }) => {
  const curs = React.useRef(null)
  const [darkMode, setDarkMode] = React.useState(false)

  React.useEffect(() => {
    setDarkMode(window.location.pathname !== "/")

    window.onpopstate = (e) => {
      if (window.location.pathname === "/") {
        setDarkMode(false)
      } else {
        setDarkMode(true)
      }
    }
  }, [])

  React.useEffect(() => {
    const mouseMoveHandler = (e) => {
      const x = e.clientX
      const y = e.clientY
      curs.current.style.left = x + "px"
      curs.current.style.top = y + "px"
    }

    document.addEventListener("mousemove", mouseMoveHandler)

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler)
    }
  }, [])

  const [darkModeSetTimeOut, setDarkModeSetTimeOut] = React.useState(null)
  React.useEffect(() => {
    if (!darkMode) {
      setTimeout(() => {
        document.body.style.setProperty("--color-primary", "#000")
        const images = document.querySelectorAll("img")
        images.forEach((image) => {
          if (image.id === "arrow") {
            image.style.filter = "invert(0)"
          }
        })
      }, 200)
      const a = setTimeout(() => {
        if (!darkMode) {
          document.body.style.setProperty("--color-secondary", "#fff")
        }
      }, 700)
      setDarkModeSetTimeOut(a)
    } else {
      clearTimeout(darkModeSetTimeOut)
      setTimeout(() => {
        document.body.style.setProperty("--color-primary", "#fff")
        const images = document.querySelectorAll("img")
        images.forEach((image) => {
          if (image.id === "arrow") {
            image.style.filter = "invert(1)"
          }
        })
        document.body.style.setProperty("--color-secondary", "transparent")
      }, 200)
    }
  }, [darkMode])

  return (
    <>
      <div className="background">
        <div
          className={
            darkMode
              ? "background__circle background_darkmode"
              : "background__circle"
          }
        ></div>
      </div>
      <div className="fixedbgwhite"></div>
      <div className="cursor" ref={curs}></div>
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <main>{children}</main>
      {children.key === "/info" ? null : (
        <Footer darkMode={darkMode} setDarkMode={setDarkMode} />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
